import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { PrimeIcons } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TokenInterceptor} from "@deuna/bo-sh-helpers-ng";

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    CalendarModule,
  ],
  providers: [
    PrimeIcons,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          newClientName: {
            name: 'backend',
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
              },
            },
            link: httpLink.create({
              uri: environment.backend,
              extractFiles: (body) => extractFiles(body, isExtractableFile),
            }),
          },
        };
      },
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
