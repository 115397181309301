// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: 'https://backoffice.dev.deunalab.com/customercare-api/internal/graphql',
  encryptKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxRkIqonVQzwigbizquRd1EQJfPIiqFeElLtzDiRk+J+vjPAfwI1BJfaolGs7iColKZJtoM0W0O2HzttOuaggnBRf4hGY3r3PKWGUHIN1x2LuBL4hPMQEU53nTBdiRagXXG//TWD7Be8rbRemNUzhyGffDQKHTzLVl5q3jLjZBpQ6E76q9AdQwvP3tAd1mmVDye1NF8AZeglx9qvsnhKZkXtWrFg4IUp518lOBE4RQxz2RIAwEVbFlhyw+r3JppKqBmERKPYSHbH3Wru0zHvS/0uVIzDZUpPX9J0mNZfOKp3g/WH6uE4fNTHxqr5nBlwg4VtozQcnDUzaWyCniR4BHwIDAQAB"
};

